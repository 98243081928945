import React from "react"
import { Link } from "gatsby"

import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"

import { isBoolean } from "../../../utils/global"
const AuthErrorMessage = props => {
  const {
    url = "/help",
    text = "If you would like to attend this event please",
    linkText = "click here",
    afterText = isBoolean(process.env.LOGIN_WITHOUT_PASSWORD)? 
    " to contact the organisers if you have any question."
    :" to contact the organisers.",
  } = props
  return (
    <div className={"mb-40"}>
      <Text globalModifiers={"text-size-medium font-secondary-regular mt-10"}>
        {text} <Link to={url} className={"link text-underline"}>
          {linkText}
        </Link>
        {afterText}
      </Text>
    </div>
  )
}

export default AuthErrorMessage
