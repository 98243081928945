import { EMAIL_REGEX, STRING_REGEX } from "../constants"
import sanitizeHtml from "sanitize-html"

export const isValidEmail = email => {
  return EMAIL_REGEX.test(email)
}

export const isValidString = text => {
  return STRING_REGEX.test(text)
}

const defaultSanitizeOptions = {
  allowedTags: [],
  allowedAttributes: [],
  textFilter: text => text.replace(/&amp;/g, "&"), // sanitize-html converts & to &amp; by default
}
export const isInputHtml = (text, options = {}) => {
  const sanitizedText = sanitizeHtml(text, {
    ...defaultSanitizeOptions,
    ...options,
  })
  return sanitizedText !== text
}
